.player-wrapper {
    position: absolute;
}

.player-card {
    position  : relative;
    display   : block;
    width     : 61px;
    //height  : 150px;


    .flip-card-front,
    .flip-card-back {
        position                   : absolute;
        width                      : 100%;
        height                     : 100%;
        -webkit-backface-visibility: hidden;
        backface-visibility        : hidden;
    }

    .flip-card {
        background-color: transparent;
        perspective     : 1000px;
        align-self      : center;
        box-sizing      : border-box;
        width           : 62px;
        height          : 91px;
        opacity         : 1;
        display         : flex;

        &.show {
            .flip-card-inner {
                transform: rotateY(180deg);
            }
        }

        .flip-card-front {
            justify-content: center;
            align-items    : center;
            border-radius  : 3px;
            border         : none;
            color          : #333;
            transform      : rotateY(180deg);
            display        : flex;
            flex-direction : column;
            justify-content: center;
            align-items    : center;

            .label {
                font-size  : 28px;
                font-weight: 600;
            }
        }

        .flip-card-inner {
            position       : relative;
            width          : 61px;
            height         : 92px;
            text-align     : center;
            transition     : transform 0.8s;
            transform-style: preserve-3d;
        }

    }

    &.me {
        top: -14px;

        .flip-card {
            width : 81px;
            height: 120px;
        }

        .flip-card-inner {
            width : 81px;
            height: 119px;
        }

        .flip-card-name {
            background   : #282e38;
            color        : #fff;
            border-radius: 10px;
            padding      : 11px 10px 0px 10px;
            position     : absolute;
            left         : 0;
            min-width    : 81px;
            text-align   : center;
            max-width    : 150px;
            text-overflow: ellipsis;
            white-space  : nowrap;
            height       : 35px;
            overflow     : hidden;
        }

        .flip-card-front.dont-know p {
            font-size: 20px;
        }
    }

    .flip-card-name {
        font-weight: 500;
        font-size  : 16px;
        line-height: 16px;
        position   : relative;
    }

    .flip-card-status {
        position       : absolute;
        width          : 50px;
        height         : 50px;
        top            : 0;
        right          : 0;
        border-radius  : 50%;
        margin-top     : -35%;
        margin-right   : -35%;
        background-size: cover !important;
    }

    .flip-card-poke {
        position         : absolute;
        width            : 31px;
        height           : 31px;
        bottom           : -4px;
        right            : -44px;
        background-repeat: no-repeat;
        border-radius    : 50%;
        background-color : #fff;
        box-shadow       : 0px 2px 3px 1px #a4a4a4;
        border           : none;
        transition       : 150ms linear;
        animation-name   : bell-in;


        animation-duration       : 400ms;
        animation-timing-function: ease-in-out;
        animation-iteration-count: 1;
        animation-fill-mode      : forwards;

        &:hover {
            background-color: #4182fc;
        }


        &.clicked {
            animation: bell-click 400ms ease-in 0s 1 normal forwards;
        }

        .poke-bell {
            width  : 21px;
            height : 21px;
            display: block;
            margin : 0 auto;
        }
    }


    .flip-card.choosen .flip-card-front {
        border-width: 2px;
    }

    .flip-card-previous {
        position     : absolute;
        width        : 100%;
        height       : 100%;
        top          : 0;
        left         : -50%;
        background   : #e6effe;
        text-align   : left;
        display      : flex;
        align-items  : center;
        z-index      : -1;
        border-radius: 8px;
    }

    .flip-card-previous p {
        width      : 50%;
        text-align : center;
        color      : #9ab0d5;
        font-size  : 28px;
        font-weight: 600;
        margin-top : 11px;
    }

    .dont-know-player {
        margin: 0 auto;
    }

    .player-card-wrap {
        position: absolute;
    }

    .triangle {
        margin-bottom     : 13px;
        //top             : -15px;
        left              : 36px;
        top               : -40px;
        position          : absolute;
        background-color  : #182C4E;
        text-align        : left;
    }

    .triangle:before,
    .triangle:after {
        content         : '';
        position        : absolute;
        background-color: inherit;
    }

    .triangle,
    .triangle:before,
    .triangle:after {
        width                  : 1em;
        height                 : 1em;
        border-top-right-radius: 30%;
    }

    .triangle {
        transform: rotate(0) skewX(-30deg) scale(1, .866);
    }

    .triangle:before {
        transform: rotate(-135deg) skewX(-45deg) scale(1.414, .707) translate(0, -50%);
    }

    .triangle:after {
        transform: rotate(135deg) skewY(-45deg) scale(.707, 1.414) translate(50%);
    }

    .flip-card-status.character {
        &.in {
            animation-name: icon-in;
        }

        &.out {
            animation-name: icon-out;
        }

        animation-duration       : 400ms;
        animation-timing-function: ease-in-out;
        animation-iteration-count: 1;
        animation-fill-mode      : forwards;
    }
}

@keyframes icon-in {
    0.0% {
        transform: scale(0.95) translateY(15px);
        opacity  : 0;
    }

    100% {
        transform: scale(1) translate(0px);
        opacity  : 1;
    }
}

@keyframes icon-out {
    0.0% {
        transform: scale(1) translate(0px);
        opacity  : 1;
    }

    100% {

        transform: scale(0.95) translateY(-15px);
        opacity  : 0;

    }
}

@keyframes bell-in {
    0.0% {
        transform: scale(0.9) translateX(-30px);
        opacity  : 0;
    }

    100% {
        transform: scale(1) translate(0px);
        opacity  : 1;
    }
}

@keyframes bell-click {
    0.0% {
        opacity: 1;
    }

    100% {
        transform: scale(600%);
        opacity  : 0;
    }

    29.5% {
        transform: scale(60%);
    }
}