.logo-poker {
    background       : url(./images/logo-poker.svg);
    background-size  : 246px 53px;
    background-repeat: no-repeat;
}

.logo-7pace {
    background       : url(./images/logo-7pace.svg);
    background-size  : 98px 33px;
    background-repeat: no-repeat;
}

.playing-card {
    background-repeat: no-repeat;
    background-size  : cover;
    background-color : transparent;

    &.back {
        background-image: url(./images/card-back.svg);
    }

    &.front {
        background-image: url(./images/card-front-border.svg);


        &.me {
            background-image: url(./images/card-front-border-me.svg);
        }
    }


}

.login-image {
    background       : url(./images/login-image.svg);
    background-size  : 510px 733px;
    background-repeat: no-repeat;
}


.rainbow {
    background-image : url(./images/rainbow.png);
    background-size  : cover;
    background-repeat: no-repeat;
    height           : 100%;

    @media (min-width: 768px) and (-webkit-min-device-pixel-ratio: 2),
    (min-width: 768px) and (min-resolution: 192dpi) {
        background-image: url(./images/rainbow@2x.png);
    }
}

.character {
    background-repeat: no-repeat;

    &.dont-know {
        background-image: url(./images/role-dont-know.png);

        @media (min-width: 768px) and (-webkit-min-device-pixel-ratio: 2),
        (min-width: 768px) and (min-resolution: 192dpi) {
            background-image: url(./images/role-dont-know@2x.png);
        }

        background-repeat: no-repeat;


        &.in-panel {
            height         : 40px;
            width          : 40px;
            background-size: contain;
            position       : absolute;
            margin-left    : 90px;
            margin-top     : -34px;
        }

        &.in-table {
            height         : 40px;
            width          : 40px;
            background-size: contain;
            display        : inline-block;
            vertical-align : top;
        }

        &.in-player {
            height         : 40px;
            width          : 40px;
            background-size: contain;
        }
    }

    &.idle {
        background: url(./images/role-idle-selected.svg);
    }

    &.idle-unselected {
        background: url(./images/role-idle-unselected.svg);
    }

    &.thinking {
        background: url(./images/role-thinking-selected.svg);
    }

    &.thinking-unselected {
        background: url(./images/role-thinking-unselected.svg);
    }

    &.voted {
        background: url(./images/role-voted-selected.svg);
    }

    &.voted-unselected {
        background: url(./images/role-voted-unselected.svg);
    }

    &.Player {
        background: url(./images/role-player.svg);
    }

    &.Observer {
        background: url(./images/role-observer.svg);
    }
}

.table-paging {
    &.selected {
        background     : url(./images/selected-table-border.svg);
        background-size: 94px 82px;

        .user {
            background     : url(./images/selected-table-User.svg);
            background-size: 20px 20px;
        }
    }

    &.unselected {
        background     : url(./images/unselected-table-border.svg);
        background-size: 94px 82px;

        .user {
            background     : url(./images/unselected-table-User.svg);
            background-size: 20px 20px;
        }
    }
}

.check {
    background     : url(./images/check-icon.svg);
    background-size: 20px 20px;
}

.copy-paste {
    background     : url(./images/copy-paste-icon.svg);
    background-size: 21px 21px;
}

.settings-icon {
    background     : url(./images/settings-icon.svg);
    background-size: 24px 24px;
}

.logout-icon {
    background     : url(./images/logout-icon.svg);
    background-size: 24px 24px;
}

.share-icon {
    background     : url(./images/share-icon.svg);
    background-size: 24px 24px;
}

.vote-history {
    background     : url(./images/fast-backward.svg);
    background-size: 14px 10px;
}

.fire {
    background     : url(./images/fire-icon.svg);
    background-size: 24px 24px;
}

.plus-icon {
    background     : url(./images/plus-icon.svg);
    background-size: 16px 16px;
}

.refresh-icon {
    background     : url(./images/refresh-icon.svg);
    background-size: 17px 17px;
}

.poke-bell {
    background     : url(./images/bell-solid.svg);
    background-size: 21px 21px;
}