.game-page-container {
    &.reconnecting {
        filter     : blur(4px);
        //animation: blur 2s ease 0s both;
    }

    transition: 1s filter linear,
    1s -webkit-filter linear;
}

@keyframes blur {
    0% {
        filter: blur(0px);
    }

    100% {
        filter: blur(4px);
    }
}

.left-control-panel {
    position: absolute;
}

.game-stat {
    position: absolute;
    bottom  : 20px;

    //has light gray color
    .time {
        color           : rgb(68, 68, 68);
        font-size       : 14px;
        display         : inline-block;
        padding         : 5px;
        background-color: #1061F91A;
        border-radius   : 5px;
    }

    .actor {
        color        : rgb(146, 146, 146);
        font-size    : 12px;
        display      : inline-block;
        padding      : 5px;
        border-radius: 5px;

        &.in {
            animation: game-stat-animation-in 400ms ease-out 0s 1 normal forwards;
        }

        &.out {
            animation: game-stat-animation-out 400ms ease-in 0s 1 normal forwards;
        }

        .name {
            background-color: #282e3873;
            color           : #fff;
            border-radius   : 5px;
            padding         : 5px;
        }
    }
}

@keyframes game-stat-animation-in {
    0.0% {
        transform: scale(0.95) translateY(15px);
        opacity  : 0;
    }

    100% {
        transform: scale(1) translate(0px);
        opacity  : 1;
    }
}

@keyframes game-stat-animation-out {
    0.0% {
        transform: scale(1) translate(0px);
        opacity  : 1;
    }

    100% {

        transform: scale(0.95) translateY(-15px);
        opacity  : 0;

    }
}