@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,700;1,300&display=swap');

// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "../node_modules/bootstrap/scss/functions";

// 2. Include any default variable overrides here
// Bootstrap and its default variables
//bootstrap customization
$popover-border-width: 2px;
$popover-border-color: #CEDFFE;
$popover-arrow-width : 1.2rem;
$popover-arrow-height: .7rem;
$popover-arrow-color : $popover-border-color;

// 3. Include remainder of required Bootstrap stylesheets

@import "../node_modules/bootstrap/scss/variables";
@import "../node_modules/bootstrap/scss/mixins";

@import "../node_modules/bootstrap/scss/_utilities";

// 4. Include any optional Bootstrap components as you like
@import "../node_modules/bootstrap/scss/root";
@import "../node_modules/bootstrap/scss/reboot";
@import "../node_modules/bootstrap/scss/type";
@import "../node_modules/bootstrap/scss/images";
@import "../node_modules/bootstrap/scss/containers";
@import "../node_modules/bootstrap/scss/grid";


// Optional Bootstrap components here
@import "../node_modules/bootstrap/scss/bootstrap-utilities.scss";
@import "../node_modules/bootstrap/scss/root";
@import "../node_modules/bootstrap/scss/type";
@import "../node_modules/bootstrap/scss/popover";
@import "../node_modules/bootstrap/scss/alert";
@import "../node_modules/bootstrap/scss/spinners";
@import "../node_modules/bootstrap/scss/progress";
@import "../node_modules/bootstrap/scss/tables";
@import "../node_modules/bootstrap/scss/card";
@import "../node_modules/bootstrap/scss/modal";
@import "../node_modules/bootstrap/scss/close";
@import "../node_modules/bootstrap/scss/buttons";
@import "../node_modules/bootstrap/scss/button-group";
@import "../node_modules/bootstrap/scss/dropdown";
@import "../node_modules/bootstrap/scss/transitions";






@import "./styles/buttons.scss";

// etc
body {
    font-family: 'Open Sans', sans-serif;
}

#root {
    background-color     : #ECF7FF;
    background-image     : url('./images/background.png');
    display              : flex;
    justify-content      : center;
    align-items          : center;
    background-position-y: -200px;
    background-size      : 100%;

    @media (min-width: 768px) and (-webkit-min-device-pixel-ratio: 2),
    (min-width: 768px) and (min-resolution: 192dpi) {
        background-image: url('./images/background@2x.png');
    }

    @media screen and (max-width: 800px) {
        background-image: none;
        background-color: white;
    }

    .root-content-wrapper {
        width    : 85%;
        min-width: 800px;
        height   : 775px;

        @media screen and (max-width: 800px) {
            width     : 100%;
            min-width : unset;
            min-height: 100vh;
        }

        .game-container {
            background-color: white;
            box-shadow      : 0px 10px 40px #282E3824;
            border-radius   : 10px;
            height          : 650px;
            position        : relative;
            padding         : 20px;

            @media screen and (max-width: 800px) {
                border-radius: 0;
                box-shadow   : none;
            }

            .buttons-container {
                margin-left   : auto;
                margin-right  : auto;
                height        : 100%;
                width         : auto;
                display       : flex;
                flex-direction: column;
                float         : right;
            }
        }
    }
}