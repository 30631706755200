.game-header-container {
    display        : flex;
    justify-content: space-between;
    height         : 75px;

    @media screen and (max-width: 800px) {
        border-bottom: 1px solid lightgrey;
    }

    .logo-poker {
        width      : 246px;
        height     : 53px;
        margin-left: 20px;
        margin-top : 11px;
    }

    .game-header-buttons {
        display    : flex;
        align-items: center;

        .button-container {
            cursor : pointer;
            display: flex;

            .share-icon,
            .settings-icon,
            .logout-icon {
                width : 24px;
                height: 24px;
            }

            &.share {
                margin-right: 20px;

                .share-icon {
                    margin-right: 9px;
                }

                margin-left   : 9px;
                color         : #1C5BFF;
                text-transform: uppercase;
                font-size     : 13px;
                font-weight   : 700;
                display       : flex;
                align-items   : flex-end;
            }

            &.logout {
                margin-left: 10px;
            }

            &.settings,
            &.logout {
                width           : 44px;
                height          : 44px;
                background-color: #FFFFFF;
                box-shadow      : 0px 2px 1px #A2C1FF;
                border-radius   : 10px;
                justify-content : space-around;
                align-items     : center;

                &:hover {
                    transition      : 150ms linear;
                    background-color: #4182fc;
                }
            }


        }
    }
}