.results-section {
    width     : 223px;
    text-align: center;
    position  : absolute;

    .results-table {

        td,
        th {
            vertical-align: middle;
            padding       : 0;
        }

        th {
            font-size       : 12px;
            font-weight     : 400;
            color           : #797E84;
            background-color: #F4F4F5;
        }

        tr:nth-of-type(odd) {
            background-color: transparent;
        }

        tr.striped {
            background-color: #F4F4F5;
        }
    }
}