@import './../../../mixins.scss';
@import '../../../styles/labels';


.share-room-popup-container {
    width    : 546px;
    height   : 182px;
    max-width: 546px !important;


    .popover-body {
        padding   : 0px !important;
        display   : flex;
        box-shadow: 0px 10px 40px #282E3824;
        overflow  : hidden;

        .share-qr-code-container {
            width : 180px;
            height: 180px;
            border: 0px solid;

            border-radius: 2px;
        }

        .share-link-container {
            padding: 10px;

            h1 {
                color     : #282E38;
                font-size : 21px;
                margin-top: 15px;
            }

            .link-row {
                display    : flex;
                align-items: center;

                .share-link {
                    width           : 289px;
                    height          : 54px;
                    border-radius   : 10px;
                    background-color: #E6EFFE;
                    padding-left    : 16px;
                    padding-right   : 16px;
                    line-height     : 49px;
                }

                .copy-paste {
                    width      : 21px;
                    height     : 21px;
                    margin-left: 20px;
                    cursor     : pointer;
                    @include transition025;

                    &:hover {
                        opacity: 0.8;
                    }
                }
            }
        }

        .link-text input {
            width      : 100%;
            border     : none;
            background : none;
            color      : #1C5BFF;
            font-weight: 700;
            font-size  : 16px;
            outline    : none;
        }
    }

    .success-label {
        position   : absolute;
        margin-left: 6px;
    }
}