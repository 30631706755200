.container-control-panel {
    //float: right;
    height: 550px;
    width : 159px;

    align-self: flex-end;


    .cards {
        display        : flex;
        justify-content: center;
        align-items    : center;
        flex-direction : column;
        position       : relative;


        .card-row {
            transition                : transform 300ms;
            transform                 : translate(0);
            transition-delay          : 30ms;
            transition-timing-function: ease-out;
            display                   : flex;
            position                  : absolute;
            left                      : -20px;
            opacity                   : 0;
            transition-property       : opacity 200ms;
            transition-timing-function: cubic-bezier(0.075, 0.820, 0.165, 1.000);


            .poker-card {
                display            : flex;
                justify-content    : center;
                align-items        : center;
                border-radius      : 10px;
                border             : 2px solid #CEDFFE;
                color              : #3d4e48;
                width              : 106px;
                background         : white;
                margin-bottom      : 5px;
                outline            : none;
                border-bottom      : 4px solid #CEDFFE;
                font-weight        : bolder;
                font-size          : large;
                line-height        : 20px;
                padding-top        : 6px;
                transition         : 100ms linear;
                transition-property: background-color, color, border-color;

                &.dont-know {
                    font-size  : medium;
                    text-align : left;
                    width      : 140px;
                    display    : inline-block;
                    padding-top: 5px;
                }

                &.active {
                    background   : #1061F9 0% 0% no-repeat padding-box;
                    color        : white;
                    border       : 2px solid #1061F9;
                    border-bottom: 4px solid #1061F9;
                }

                &:hover {
                    cursor             : pointer;
                    transition         : 150ms linear;
                    background-color   : #1061F9;
                    color              : white;
                    border-color       : #0f4ec2;
                    border-bottom-color: #0f4ec2;
                }
            }
        }
    }
}