.login-page-footer {
    margin-top: 30px;
    font-size : 13px;
    color     : #a3a6aa;
}

.powered-by-7pace-footer {
    display       : flex;
    flex-direction: row;
    align-items   : center;

    p {
        font-size: 13px;
        color    : #282E38;
        margin   : 0px;
    }

    .logo-7pace {
        width      : 98px;
        height     : 33px;
        margin-left: 10px;
    }
}

.game-field-footer {
    display       : flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .powered-by-7pace-footer {
        margin-top: 15px;
    }
}

.no-underline{
    a{
        text-decoration: underline;
    }
    text-decoration: none;
}