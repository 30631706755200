.game-buttons {
    float: right;

    button {
        border       : none;
        border-radius: 10px;
        height       : 44px;
        color        : #fff;
        line-height  : 44px;
        font-weight  : 400;
        font-size    : 13px;
        padding-left : 14px;
        padding-right: 14px;
        transition   : background-color 500ms linear;

        &:disabled {
            opacity         : 0.5;
            background-color: gray;

            &:hover {
                background-color: gray;
            }
        }
    }



    .show-cards {
        background-color: #1C5BFF;

        &:hover {
            background-color: rgb(29, 87, 235);
        }
    }

    .start-new {
        background-color: #45D3D3;
        margin-left     : 10px;

        &:hover {
            background-color: rgb(66, 194, 195);
        }
    }



    .character {
        width            : 50px;
        height           : 55px;
        background-repeat: no-repeat;

        &.voted {
            background-position: 0 6px;
        }

        &.thinking {
            background-position: 0 7px;
        }

        &.idle {
            background-position: 0 -1px;
        }
    }

    .flex-container {
        display                : -ms-inline-flexbox;
        display                : -webkit-inline-flex;
        display                : inline-flex;
        -webkit-flex-direction : row;
        -ms-flex-direction     : row;
        flex-direction         : row;
        -webkit-flex-wrap      : nowrap;
        -ms-flex-wrap          : nowrap;
        flex-wrap              : nowrap;
        -webkit-justify-content: flex-start;
        -ms-flex-pack          : start;
        justify-content        : flex-start;
        -webkit-align-content  : stretch;
        -ms-flex-line-pack     : stretch;
        align-content          : stretch;
        -webkit-align-items    : flex-start;
        -ms-flex-align         : start;
        align-items            : flex-start;
    }

    .flex-item {}


    .legend-label {
        color     : #B2B2B2;
        font-size : 9px;
        position  : absolute;
        bottom    : -15px;
        left      : 0;
        right     : 0;
        text-align: center;
    }

    .legend-item {
        position    : relative;
        margin-right: 10px;

        top: -6px;

        &.thinking-unselected {
            margin-right: 20px;
        }
    }

    .buttons-block {
        width: 220px;
    }
}