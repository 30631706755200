.reconnecting-indicator {
    bottom: 40px;
}

.reconnecting-indicator-cover {
    position  : fixed;
    top       : 0;
    left      : 0;
    background: rgba(0, 0, 0, 0.6);
    z-index   : 500;
    width     : 100%;
    height    : 100%;

    transition: background-color 0.5s;
    animation : fadein 2s;

    &.hiding {
        background: rgba(0, 0, 0, 0);
        transition: background-color 0.5s;
    }

    &.disconnected {
        background: rgba(0, 0, 0, 0.9);
    }
}

@keyframes fadein {
    from {
        opacity: 0
    }

    to {
        opacity: 1
    }
}