.button {

    border       : none;
    border-radius: 10px;
    height       : 44px;
    color        : #fff;
    line-height  : 44px;
    font-weight  : 400;
    font-size    : 13px;
    padding-left : 14px;
    padding-right: 14px;
    transition   : background-color 500ms linear;

    &.success {
        background-color: #45D3D3;
        margin-left     : 10px;

        &:hover {
            background-color: rgb(66, 194, 195);
        }
    }

    &.cancel {
        background-color: #1061F91A;
        color           : #282E38;

        &:hover {
            background-color: lighten($color: #1061F91A, $amount: 0.1);
        }
    }

    &:disabled {
        opacity         : 0.5;
        background-color: gray;

        &:hover {
            background-color: gray;
        }
    }
}