body {
    display        : flex;
    justify-content: space-between;
    flex-direction : column;
    min-height     : 100vh;
    font-weight    : 500;
    color          : #3d4e48;
}

#root {
    min-height     : 100vh;
    display        : flex;
    flex-direction : column;
    justify-content: space-between;
}