.observers {
    display    : flex;
    font-size  : 14px;
    line-height: 34px;

    .observers-title {
        color      : #797E84;
        font-weight: 400;
    }

    .observer-user {
        color           : #181617;
        background-color: #282E3815;
        height          : 30px;
        border-radius   : 30px;

        font-weight  : 600;
        padding-left : 12px;
        padding-right: 12px;
        margin-left  : 10px;
    }
}