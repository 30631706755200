.login-page-wrapper {
    background     : white;
    width          : 100%;
    display        : flex;
    justify-content: center;
    align-items    : center;

    @keyframes opacity-show {
        0% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }

    @keyframes shake {

        10%,
        90% {
            transform: translate3d(-1px, 0, 0);
        }

        20%,
        80% {
            transform: translate3d(2px, 0, 0);
        }

        30%,
        50%,
        70% {
            transform: translate3d(-4px, 0, 0);
        }

        40%,
        60% {
            transform: translate3d(4px, 0, 0);
        }
    }

    .shaky {
        animation          : shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
        transform          : translate3d(0, 0, 0);
        backface-visibility: hidden;
        perspective        : 1000px;
    }

    .Dropdown-control {
        text-transform: capitalize;
        background    : #1061F91A 0% 0% no-repeat padding-box;
        width         : 289px;
        height        : 54px;
        border-radius : 10px;
        z-index       : 1;
        color         : #282E38;
        font-weight   : 700;
        font-size     : 16px;

        .Dropdown-placeholder {
            line-height : 40px;
            padding-left: 62px;
        }


    }

    .Dropdown-arrow {
        border-color: #282E38 transparent transparent;
        top         : 24px;
    }

    .is-open .Dropdown-arrow {
        border-color: transparent transparent #282E38;
        border-width: 0 5px 5px;
    }

    .player-image {
        width           : 59px;
        height          : 52px;
        background-color: #CCEAFF;
        margin-right    : 25px;
        position        : absolute;
        border-radius   : 10px 0px 0px 10px;
        z-index         : 100;
        left            : 1px;
        top             : 28px;

        .character {
            &.Player {
                width   : 60px;
                height  : 88px;
                position: absolute;
                left    : 0px;
                top     : -35px;
            }

            &.Observer {
                width : 59px;
                height: 53px;
            }
        }
    }

    .Dropdown-menu {
        top          : 60px;
        border       : 2px solid #1061F999;
        border-radius: 10px;
    }



    .validation-error {
        color    : #AF4034;
        position : absolute;
        top      : 85px;
        left     : 10px;
        font-size: 13px;
        animation: opacity-show 0.25s ease-in 1;
    }

    .login-page-container-outer {
        display: flex;
        height : 100vh;

        .login-page-container-inner {
            margin: auto;

            .login-page-body-container {
                display       : flex;
                flex-direction: row;

                .login-page-left-container {
                    width   : 510px;
                    height  : 733px;
                    position: relative;

                    .logo-poker {
                        width : 246px;
                        height: 53px;
                        margin: 70px 0px 0px 39px;
                    }

                    .login-image {
                        width   : 510px;
                        height  : 733px;
                        position: absolute;
                        left    : 0px;
                        top     : 0px;
                    }
                }

                .login-page-right-container {
                    height : 733px;
                    padding: 74px 0 0 74px;

                    .login-page-right-header {
                        margin-bottom: 60px;

                        .login-page-header-text {
                            font-size: 36px;
                        }
                    }

                    .login-page-right-footer {
                        display       : flex;
                        flex-direction: row;
                        align-items   : center;

                        p {
                            font-size: 13px;
                            color    : #282E38;
                            margin   : 0px;
                        }

                        .logo-7pace {
                            width      : 98px;
                            height     : 33px;
                            margin-left: 10px;
                        }
                    }

                    .login-page-right-body {
                        height: 490px;

                        .login-page-row {
                            display       : flex;
                            flex-direction: column;
                            width         : 289px;
                            margin-bottom : 30px;
                            position      : relative;

                            label {
                                font-size     : 12px;
                                margin-bottom : 9px;
                                text-transform: uppercase;
                                color         : #797E84;
                                font-weight   : 500;
                            }

                            input {
                                height       : 54px;
                                border       : 2px solid #1061F999;
                                text-indent  : 11px;
                                border-radius: 10px;
                                color        : #282E38;
                                font-weight  : 700;
                                font-size    : 16px;
                                padding      : 5px 0px 0px 0px;


                                &:focus {
                                    border : 2px solid #1061F999;
                                    outline: none;
                                }

                                &::placeholder {
                                    color: #c9cbcd;
                                }
                            }

                            .login-page-enter-button {
                                margin-top      : 20px;
                                width           : 101px;
                                height          : 44px;
                                align-self      : flex-end;
                                background-color: #45D3D3;
                                border          : none;
                                font-size       : 13px;
                                color           : #FFFFFF;
                                font-weight     : 600;
                                text-transform  : uppercase;
                                border-radius   : 10px;
                                padding         : 14px;

                                &:focus {
                                    outline: none;
                                }

                                &:hover {
                                    background-color: darken($color:#45D3D3, $amount: 10);
                                }
                            }
                        }
                    }
                }
            }

            .login-page-footer {
                margin-top: 56px;
            }
        }
    }
}