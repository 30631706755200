.scale-button {
    background   : #E3E3E3 0% 0% no-repeat padding-box;
    border       : none;
    border-radius: 5px;
    margin-left  : 10px;
    padding      : 5px;
}

.scale-set {
    display       : flex;
    flex-direction: row;
    margin-bottom : 20px;

    //flex-flow     : wrap;
    .custom {
        flex-wrap: wrap;

        &>div {
            margin-top: 10px;
        }
    }
}

.section-name {
    font          : 12px/15px;
    letter-spacing: 0px;
    color         : #797E84;
    margin-bottom : 15px;
}

.scale-input {
    background       : #E3E3E3 0% 0% no-repeat padding-box;
    border           : 1px solid #E3E3E3;
    border-radius    : 5px;
    margin-left      : 10px;
    width            : 60px;
    text-align       : center;
    //  margin-top   : 10px;
    padding          : 4px;
}

.add-scale-item {
    height     : 34px;
    line-height: 34px;
}

.custom-scale-hint {
    font-size  : 10px;
    color      : #797E84;
    flex-basis : 100%;
    margin-left: 10px;
}

.break {
    flex-basis: 100%;
    height    : 0;
}

.custom-scale-set {
    display       : flex;
    flex-direction: row;
    flex-wrap     : wrap;
}

.custom-scale-set-header {
    margin-left: 10px;
}

.modal-90w {
    width    : 90%;
    max-width: none !important;
}