///////////////////////////////////////////////////////////
// Plain SASS Trigonometry Algorithm in Taylor Expansion //
//                                                       //
// Based on                                              //
//      http://japborst.net/posts/sass-sines-and-cosines //
///////////////////////////////////////////////////////////

$pi        : 3.14159265359;
$_precision: 10;

@function pow($base, $exp) {
    $value: $base;

    @if $exp>1 {
        @for $i from 2 through $exp {
            $value: $value * $base;
        }
    }

    @if $exp < 1 {
        @for $i from 0 through -$exp {
            $value: $value / $base;
        }
    }

    @return $value;
}

@function fact($num) {
    $fact: 1;

    @if $num>0 {
        @for $i from 1 through $num {
            $fact: $fact * $i;
        }
    }

    @return $fact;
}

@function _to_unitless_rad($angle) {
    @if unit($angle)=="deg" {
        $angle: $angle / 180deg * $pi;
    }

    @if unit($angle)=="rad" {
        $angle: $angle / 1rad;
    }

    @return $angle;
}

@function sin($angle) {
    $a  : _to_unitless_rad($angle);
    $sin: $a;

    @for $n from 1 through $_precision {
        $sin: $sin + (pow(-1, $n) / fact(2 * $n + 1)) * pow($a, (2 * $n + 1));
    }

    @return $sin;
}

@function cos($angle) {
    $a  : _to_unitless_rad($angle);
    $cos: 1;

    @for $n from 1 through $_precision {
        $cos: $cos + (pow(-1, $n) / fact(2*$n)) * pow($a, 2*$n);
    }

    @return $cos;
}

@function tan($angle) {
    @return sin($angle) / cos($angle);
}

.poker-center-table {
    display        : flex;
    position       : absolute;
    width          : 300px;
    height         : 200px;
    background-size: cover;
    left           : 50%;
    margin-left    : -150px;
    margin-top     : -100px;
    top            : 50%;
    background     : url(../../images/unselected-table-border.svg) no-repeat center;
    background-size: cover;
    align-items    : center;
    justify-content: flex-start;
    flex-direction : column;
    padding-top    : 70px;

    .table-state-label {
        color: #282E38;
        width: 100%;
    }

    .progress {
        width           : 196px;
        background-color: #0E2D7F33;
    }

    .table-amount {
        text-align : center;
        line-height: 40px;
    }

    .point {
        font-size  : 32px;
        font-weight: bold;
        color      : #282E38;

        .consensus {
            animation: consensusAnimation 1s ease-out 0s 1 normal forwards;
        }
    }
}

.table-holder {
    position: relative;
}

.cards-wrap {
    position: absolute;
    top     : 42%;
    left    : 48%;
    overflow: visible;
    height  : 1px;
    width   : 1px;
}

@keyframes consensusAnimation {
    0.0% {
        transform: scale(0.7) skew(0deg);
    }

    32.5% {
        transform: scale(1.6);
    }

    77.2% {
        transform: scale(0.9);
    }

    100% {
        transform: scale(1);
    }

}



//
// Config
$size         : 26px;
$animDuration : 0.8s;
$animTiming   : ease-in;
$animIteration: 1000;
$animStep     : 100% / 27;

// Layers & Colors
$heartColors: #AAB8C2,
#061464;
$ringColors: #E2264D,
#CC8EF5;
$circles: ((first: (start: #8CE8C3, end:#A068CE),
        second: (start: #8BE7C2, end:#B752E1)),
    (first: (start: #90D2FA, end:#99E9C8),
        second: (start: #91D1F9, end:#BAE3D7)),
    (first: (start: #CC8EF5, end:#D3F491),
        second: (start: #CB8DF4, end:#DCE483)),
    (first: (start: #8CE8C3, end:#59C392),
        second: (start: #8CE8C3, end:#67CD9F)),
    (first: (start: #F58EA7, end:#CAADC7),
        second: (start: #F48DA6, end:#959FF3)),
    (first: (start: #91D2FA, end:#CA5ED8),
        second: (start: #91D2FA, end:#A975D1)),
    (first: (start: #92D3FC, end:#C35DD1),
        second: (start: #CB8DF4, end:#90E0BE)));

// Computations
$circlesLength      : length($circles);
$angleBetweenCircles: 360deg / $circlesLength;
$circleSize         : $size / 6;
$shiftAngleBeginning: -135deg;

// Functions
@function setStep($n) {
    @return ($n - 1) * $animStep
}

@function setBoxShadow($distance1, $distance2, $size1, $size2, $shiftAngle, $colorRatio) {
    $boxS: ();

    @for $i from 1 through length($circles) {
        $circle           : nth($circles, $i);
        $order            : $i - 1;
        $angle1           : ($order * $angleBetweenCircles) + $shiftAngleBeginning;
        $angle2           : $angle1 + $shiftAngle;
        $distanceRatio1   : $size * $distance1;
        $distanceRatio2   : $size * $distance2;
        $firstCircle      : map-get($circle, first);
        $firstCircleStart : map-get($firstCircle, start);
        $firstCircleEnd   : map-get($firstCircle, end);
        $secondCircle     : map-get($circle, second);
        $secondCircleStart: map-get($secondCircle, start);
        $secondCircleEnd  : map-get($secondCircle, end);

        $boxS: append($boxS,
                cos($angle1) * $distanceRatio1 sin($angle1) * $distanceRatio1 0 $circleSize * $size1 mix($firstCircleStart, $firstCircleEnd, $colorRatio));

        $boxS: append($boxS,
                cos($angle2) * $distanceRatio2 sin($angle2) * $distanceRatio2 0 $circleSize * $size2 mix($secondCircleStart, $secondCircleEnd, $colorRatio));
    }

    @return join($boxS, (), "comma");
}

// Animations
@keyframes heart {

    #{setStep(1)},
    #{setStep(6)} {
        transform: scale(0);
    }

    #{setStep(13)} {
        transform: scale(1.25);
    }

    #{setStep(18)} {
        transform: scale(1);
    }

    #{setStep(23)} {
        transform: scale(1.025);
    }

    #{setStep(28)} {
        transform: scale(1);
    }
}

@keyframes ring {
    #{setStep(1)} {
        height      : 0;
        width       : 0;
        border-width: 0;
        margin-top  : 0;
        margin-left : 0;
    }

    #{setStep(2)} {
        height      : 0;
        width       : 0;
        border-width: $size * 0.1;
        margin-top  : -$size * 0.1;
        margin-left : -$size * 0.1;
        border-color: nth($ringColors, 1);
    }

    #{setStep(3)} {
        height      : 0;
        width       : 0;
        border-width: $size * 0.7;
        margin-top  : -$size * 0.7;
        margin-left : -$size * 0.7;
    }

    #{setStep(4)} {
        height      : 0;
        width       : 0;
        border-width: $size * 0.8;
        margin-top  : -$size * 0.8;
        margin-left : -$size * 0.8;
    }

    #{setStep(5)} {
        height      : 0;
        width       : 0;
        border-width: $size * 0.85;
        margin-top  : -$size * 0.85;
        margin-left : -$size * 0.85;
    }

    #{setStep(6)} {
        width       : $size * 1.2;
        height      : $size * 1.2;
        border-width: $size * 0.25;
        border-color: nth($ringColors, 2);
    }

    #{setStep(7)} {
        width       : $size * 1.6;
        height      : $size * 1.6;
        border-width: $size * 0.05;
    }

    #{setStep(8)},
    #{setStep(28)} {
        width       : $size * 1.7;
        height      : $size * 1.7;
        border-width: 0;
        margin-top  : -$size * 0.85;
        margin-left : -$size * 0.85;
    }
}

@keyframes circles {

    #{setStep(1)},
    #{setStep(6)} {
        box-shadow: setBoxShadow($distance1: 0.75, $distance2: 0.75, $size1: -0.500, $size2: -0.500, $colorRatio: 100%, $shiftAngle: -5deg);
    }

    #{setStep(7)} {
        box-shadow: setBoxShadow($distance1: 0.80, $distance2: 0.85, $size1: -0.200, $size2: -0.200, $colorRatio: 100%, $shiftAngle: -5deg);
    }

    #{setStep(15)} {
        box-shadow: setBoxShadow($distance1: 1.20, $distance2: 1.00, $size1: -0.100, $size2: -0.350, $colorRatio: 25%, $shiftAngle: -12deg);
    }

    #{setStep(23)},
    #{setStep(28)} {
        box-shadow: setBoxShadow($distance1: 1.20, $distance2: 1.00, $size1: -0.500, $size2: -0.500, $colorRatio: 0%, $shiftAngle: -12deg);
    }
}

// Styles
.heart-wrapper {
    height  : $size * 2;
    width   : 100%;
    position: relative;

    // Layer 1 : Heart
    .heart {
        display         : block;
        top             : 50%;
        left            : 50%;
        margin-top      : -18px;
        margin-left     : -8px;
        position        : absolute;
        z-index         : 0;
        font-size       : $size;
        transform-origin: center;

        .txt {
            font-size: $size;
        }
    }

    // Layer 2 : Ring
    .ring {
        display      : block;
        position     : absolute;
        border-width : 0;
        border-style : solid;
        top          : 50%;
        left         : 50%;
        border-radius: 50%;
        z-index      : 1;
        box-sizing   : content-box;
    }

    // Layer 3 : Circles
    .circles {
        display      : block;
        position     : absolute;
        height       : $circleSize;
        width        : $circleSize;
        top          : 50%;
        left         : 50%;
        margin-top   : -$circleSize / 2;
        margin-left  : -$circleSize / 2;
        z-index      : 2;
        border-radius: 50%;
    }


    // Active Styles
    &.active {

        // Layer 1 : Animation
        .heart {
            animation-name           : heart;
            animation-duration       : $animDuration;
            animation-timing-function: $animTiming;
            animation-iteration-count: $animIteration;
        }

        // Layer 2 : Animation
        .ring {
            animation-name           : ring;
            animation-duration       : $animDuration;
            animation-timing-function: $animTiming;
            animation-iteration-count: $animIteration;
        }

        // Layer 3 : Animation
        .circles {
            animation-name           : circles;
            animation-duration       : $animDuration;
            animation-timing-function: $animTiming;
            animation-iteration-count: $animIteration;
        }
    }
}